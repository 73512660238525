import axios from "axios";

const Sozlesme = (props) => {
    let xmls = '<?xml version="1.0" encoding="utf-8"?>\
    <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">\
      <soap:Header>\
        <Kullanici xmlns="http://tempuri.org/">\
          <KullaniciAdi>Bayi_satis</KullaniciAdi>\
          <Parola>Htg_7425*-</Parola>\
        </Kullanici>\
      </soap:Header>\
      <soap:Body>\
        <Paket_Listesi xmlns="http://tempuri.org/">\
          <BayiNO>328089</BayiNO>\
        </Paket_Listesi>\
      </soap:Body>\
    </soap:Envelope> ';

    axios.post('https://webservice.mobilassistance.com/Bayi/PaketSatis.asmx?op=Paket_Listesi',
        xmls,
        {
            headers:
                { 'Content-Type': 'text/xml; charset=utf-8'}
        })
        .then(res => {
            // console.log(res);
        })
        .catch(err => { console.log(err)
        
        console.log(xmls)});

    return (
        <>
            <div className="mss">
                <h2>MESAFELİ SATIŞ SÖZLEŞMESİ {props.Package}</h2>
                <p>
                    MOBIL ASSISTANCE SERVİS HİZMETLERİ DENETİM DANIŞMANLIK TİCARET LTD.ŞTİ (sözleşmede “MOBİL ASSİSTANCE ” olarak anılacaktır), Oteller, Hava yolu firmaları, Oto kiralama firmaları, Oto lastik ve tamir firmaları, Çekici firmaları, çekiciler ve diğer yol yardım araçları (bundan sonra “Servis Sağlayıcıları” olarak da anılacaktır) araçları herhangi bir sebeple yolda kalan müşterileri (bundan sonra “MÜŞTERİ” olarak da anılacaktır) en yakın oto servise taşınmasını sağlayan ve o an müşterinin ihtiyacı olan hizmetleri veren yol yardım şirketidir. MOBİL ASSİSTANCE ve Müşteri, birlikte “Taraflar” olarak da anılacaktır.
                </p>
                <h2>YÜRÜRLÜK</h2>
                <p>İşbu Sözleşme Müşteri’nin elektronik olarak onay vermesi ile karşılıklı olarak kabul edilerek yürürlüğe girmiştir.</p>
            </div>
        </>
    )
};

export default Sozlesme;