import { useSearchParams } from "react-router-dom";


 
 const YolYardimSeo = (props) => {
    const [searchParams] = useSearchParams();

     const plaka = searchParams.get("plaka"); // "text"
    const sehir = searchParams.get("sehir");
    // const c = searchParams.get("c");       // "$something"
    // const cc = searchParams.get("cc";      // "$something"
   
console.log(plaka);
console.log(sehir);
console.log(searchParams);
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="c-bg-border">
                    <div className="o-container">
                        <div className="c-col__row">
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                        </div>
                    </div>
                </div>
                <div className="o-container__wrapper">
                    ss
                </div>
            </div>
        </>
    );
};

export default YolYardimSeo;